import {useEffect, useState} from "react";
// import {Link} from 'react-router-dom';
import {Navbar} from 'react-bootstrap';

import logo from 'assets/img/logo.svg';
import discordIcon from 'assets/img/discord-icon.svg';
import tgIcon from 'assets/img/telegram-icon.svg';
import xIcon from 'assets/img/x-icon.svg';
import tiktokIcon from 'assets/img/tiktok-icon.svg';
import instagramIcon from 'assets/img/instagram-icon.svg';
import onlyfansIcon from 'assets/img/onlyfans-icon.svg';
import btnDiscordIcon from 'assets/img/btn-discord-icon.svg';
import btnTelegramIcon from 'assets/img/btn-telegram-icon.svg';

const Header = ({isMdScreen, isSmScreen, isCountdownPage = false, isDappPage = false}) => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            !isDappPage && setScroll(window.scrollY > 10);
        });
    }, [isDappPage]);

    const handleTogglerClick = () => {
        if (isMdScreen) {
            document.querySelector('body').classList.toggle('navbar-opened');
        }
    }

    const handleNavbarClose = () => {
        if (isMdScreen) {
            document.querySelector('header .navbar .navbar-toggler').click();
            document.querySelector('body').classList.remove('navbar-opened');
        }
    }

    if (isCountdownPage) {
        return (
            <header className={`${scroll ? 'scroll' : ''}`}>
                <Navbar expand="lg" data-aos="fade-down" data-aos-delay="0">
                    <a href="/" className="navbar-brand">
                        <img src={logo} alt="Logo"/>
                    </a>
                    <div className="navbar-buttons">
                        <a href="https://discord.gg/APClub" target="_blank" rel="noreferrer">
                            {!isSmScreen ? <span className="text">Join Discord</span> : null}
                            <span className="icon">
                                <img src={btnDiscordIcon} alt="Icon"/>
                            </span>
                        </a>
                        <a href="https://t.me/APClubMain" target="_blank" rel="noreferrer">
                            {!isSmScreen ? <span className="text">Join Telegram</span> : null}
                            <span className="icon">
                                <img src={btnTelegramIcon} alt="Icon"/>
                            </span>
                        </a>
                    </div>
                </Navbar>
            </header>
        );
    }

    return (
        <header className={`${scroll ? 'scroll' : ''}`}>
            <Navbar expand="lg">
                <a href="/" className="navbar-brand" data-aos="fade-down" data-aos-delay="0">
                    <img src={logo} alt="Logo"/>
                </a>
                <Navbar.Toggle onClick={handleTogglerClick} data-aos="fade-down" data-aos-delay="0">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="navbar-nav-dropdown" data-aos="fade-down" data-aos-delay="0">
                    <ul className="navbar-nav">
                        {!isDappPage && (
                            <>
                                <li className="nav-item">
                                    <a href="#home-features" className="nav-link"
                                       onClick={handleNavbarClose}>AP Features</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#home-join" className="nav-link"
                                       onClick={handleNavbarClose}>Community</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#home-feed" className="nav-link" onClick={handleNavbarClose}>Social
                                        Feed</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#home-news" className="nav-link" onClick={handleNavbarClose}>News &
                                        Updates</a>
                                </li>
                            </>
                        )}
                    </ul>
                    <ul className="navbar-links">
                        <li>
                            <a href="https://discord.gg/APClub" target="_blank" rel="noreferrer">
                                <img src={discordIcon} alt="Discord Icon"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://t.me/APClubMain" target="_blank" rel="noreferrer">
                                <img src={tgIcon} alt="Telegram Icon"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/APClubMain" target="_blank" rel="noreferrer">
                                <img src={xIcon} alt="X Icon"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@apclubmain" target="_blank" rel="noreferrer">
                                <img src={tiktokIcon} alt="TikTok Icon"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/apclubmain/" target="_blank" rel="noreferrer">
                                <img src={instagramIcon} alt="Instagram Icon"/>
                            </a>
                        </li>
                        <li>
                            <a href="/" target="_blank" rel="noreferrer" className="btn-disabled">
                                <img src={onlyfansIcon} alt="OnlyFans Icon"/>
                            </a>
                        </li>
                    </ul>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
}

export default Header;
