// import {BrowserRouter, Routes} from 'react-router-dom';
import 'aos/dist/aos.css';

import Home from './components/Home';

import './App.scss';

const App = () => {
    // const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});

    const appHeightHandle = () => {
        document.querySelector('body').style.setProperty('--app-height', window.innerHeight + 'px');
    }

    const appWidthHandle = () => {
        document.querySelector('body').style.setProperty('--app-width', window.innerWidth + 'px');
        document.querySelector('body').style.setProperty('--app-width-scale', window.innerWidth / 1440);
    }

    appHeightHandle();
    appWidthHandle();

    window.addEventListener('resize', () => {
        if (window.innerWidth < 1200) {
            appHeightHandle();
        }
        appWidthHandle();
    });

    return (
        <Home/>
        // <Routes>
        //     <Route exact path="/" component={Home}/>
        //     <Route component={NotFound}/>
        //     {/*<Switch>*/}
        //     {/*    <Route exact path="/" component={Home}/>*/}
        //     {/*    <Route component={NotFound}/>*/}
        //     {/*</Switch>*/}
        // </Routes>
    )
};

export default App;
