import {useEffect, useState} from "react";
// import AOS from "aos";
// import { aosSettings } from "aosSettings";
// import {useMediaQuery} from "react-responsive";

const ScrollToTopOnMount = () => {
    const [isLoading, setIsLoading] = useState(true);
    // const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
        setTimeout(() => setIsLoading(false), 1200);
        // window.addEventListener('load', () => {
        //     setIsLoading(false);
        //     // if (!isMdScreen) {
        //     //     AOS.init(aosSettings);
        //     // }
        // });
    }, [isLoading]);

    if (isLoading) {
        return (
            <>
                <section className="preloader">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </section>
            </>
        )
    }

    return null;
}

export default ScrollToTopOnMount;
