import {Container, Row} from "react-bootstrap";

import logo from 'assets/img/logo.svg';
import xIcon from 'assets/img/x-icon.svg';
import tgIcon from 'assets/img/telegram-icon.svg';
import discordIcon from "assets/img/discord-icon.svg";
import tiktokIcon from "assets/img/tiktok-icon.svg";
import instagramIcon from "assets/img/instagram-icon.svg";
import onlyfansIcon from "assets/img/onlyfans-icon.svg";

const Footer = () => {
    return (
        <footer>
            <Container>
                <Row>
                    <div className="logo-wrap">
                        <img src={logo} alt="Logo"/>
                        <p className="copyright">© 2023 Adult Playground</p>
                    </div>
                    <div className="nav-wrap">
                        <ul>
                            <li>
                                <a href="#home-features">AP Features</a>
                            </li>
                            <li>
                                <a href="#home-join">Community</a>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <a href="#home-feed">Social Feed</a>
                            </li>
                            <li>
                                <a href="#home-news">News & Updates</a>
                            </li>
                        </ul>
                    </div>
                    <div className="socials-wrap">
                        <p>Follow Us:</p>
                        <ul>
                            <li>
                                <a href="https://discord.gg/APClub" target="_blank" rel="noreferrer">
                                    <img src={discordIcon} alt="Discord Icon"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://t.me/APClubMain" target="_blank" rel="noreferrer">
                                    <img src={tgIcon} alt="Telegram Icon"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/APClubMain" target="_blank" rel="noreferrer">
                                    <img src={xIcon} alt="X Icon"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.tiktok.com/@apclubmain" target="_blank" rel="noreferrer">
                                    <img src={tiktokIcon} alt="TikTok Icon"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/apclubmain/" target="_blank" rel="noreferrer">
                                    <img src={instagramIcon} alt="Instagram Icon"/>
                                </a>
                            </li>
                            <li>
                                <a href="/" target="_blank" rel="noreferrer" className="btn-disabled">
                                    <img src={onlyfansIcon} alt="OnlyFans Icon"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </Row>
            </Container>
            {/*<Container fluid>*/}
            {/*    <Row>*/}
            {/*        <div className="bottom-wrap">*/}
            {/*            <p>Contract Address:</p>*/}
            {/*            <p className="num">0x565d3902d6A5A2D5CE28ff427423E88933334DD2</p>*/}
            {/*        </div>*/}
            {/*    </Row>*/}
            {/*</Container>*/}
        </footer>
    );
}

export default Footer;
