import React, {useEffect, useState, useMemo} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import MailchimpSubscribe from "react-mailchimp-subscribe"

import {useMediaQuery} from 'react-responsive';

import ScrollToTopOnMount from "components/General/ScrollToTopOnMount";
import Header from "components/General/Header";
import Footer from "components/General/Footer";

import slideOneBg from "assets/img/home-hero-slide-1.png";
import slideTwoBg from "assets/img/home-hero-slide-2.png";
import slideThreeBg from "assets/img/home-hero-slide-3.png";
import slideFourBg from "assets/img/home-hero-slide-4.png";

import joinBg from "assets/img/join-bg.png";
import joinImg from "assets/img/join-img.png";

import feedItemOne from "assets/img/home-feed-item-1.png";
import feedItemTwo from "assets/img/home-feed-item-2.png";
import feedItemThree from "assets/img/home-feed-item-3.png";

import featuresItemOne from "assets/img/home-features-item-1.png";
import featuresItemTwo from "assets/img/home-features-item-2.png";
import featuresItemThree from "assets/img/home-features-item-3.png";
import featuresItemFour from "assets/img/home-features-item-4.png";
import featuresItemFive from "assets/img/home-features-item-5.png";
import featuresItemSix from "assets/img/home-features-item-6.png";
import featuresItemSeven from "assets/img/home-features-item-7.png";
import featuresItemEight from "assets/img/home-features-item-8.png";

import newsItemOne from "assets/img/home-news-item-1.png";
import newsItemTwo from "assets/img/home-news-item-2.png";
import newsItemThree from "assets/img/home-news-item-3.png";

import btnDiscordIcon from "assets/img/btn-discord-icon.svg";
import btnTelegramIcon from "assets/img/btn-telegram-icon.svg";

import modalCloseIcon from "assets/img/modal-close-icon.svg";

const Main = () => {
    const [isOpenAgeModal, setIsOpenAgeModal] = useState(
        sessionStorage.getItem('isOpenAgeModal')
            ? JSON.parse(sessionStorage.getItem('isOpenAgeModal'))
            : true
    );
    const [isOpenFormModal, setIsOpenFormModal] = useState(false);

    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 768px)'});

    const handleClosePage = () => {
        window.open('https://google.com/', '_self');
    }

    useEffect(() => {
        if (isOpenAgeModal) {
            document.body.classList.add('hidden');
        };
    }, [isOpenAgeModal])

    const handleCloseAgeModal = () => {
        setIsOpenAgeModal(false);
        sessionStorage.setItem('isOpenAgeModal', 'false');
        document.body.classList.remove('hidden');
    }

    const handleOpenFormModal = () => {
        setIsOpenFormModal(true);
        document.body.classList.add('hidden');
    }

    const handleCloseFormModal = () => {
        setIsOpenFormModal(false);
        document.body.classList.remove('hidden');
    }

    return (
        <>
            <ScrollToTopOnMount/>
            <Header isMdScreen={isMdScreen} isSmScreen={isSmScreen}/>
            <main>
                {isOpenAgeModal ? (
                    <section id="countdown-modal" className="countdown-modal-section">
                        <div className="modal-wrap">
                            <h3>Are you 18 years old?</h3>
                            <p>This website contains adult content intended for mature audiences. Viewer discretion is
                                advised. Access to this website is restricted to individuals aged 18 years and
                                older.<br/><br/>Please confirm your age to proceed.</p>
                            <div className="buttons-wrap">
                                <button className="btn-main" onClick={() => handleCloseAgeModal()}>
                                    <span className="bg"></span>
                                    <span className="text">I'm 18 and older</span>
                                </button>
                                <button className="btn-main btn-gray" onClick={() => handleClosePage()}>
                                    <span className="bg"></span>
                                    <span className="text">I'm under 18 years old</span>
                                </button>
                            </div>
                        </div>
                    </section>
                ) : null}
                {isOpenFormModal ? (
                    <section id="home-form-modal" className="home-form-modal-section">
                        <div className="modal-wrap">
                            <div className="headline-wrap">
                                <h5>Sign Up for the Pre-order</h5>
                                <p>We will send you details on how to get started on Adult Playground</p>
                                <div className="close-wrap" onClick={() => handleCloseFormModal()}>
                                    <img src={modalCloseIcon} alt="Close"/>
                                </div>
                            </div>
                            <MailchimpForm/>
                        </div>
                    </section>
                ) : null}
                <section id="home-hero" className="home-hero-section">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="swiper-wrap">
                                    <Swiper
                                        className="home-hero-swiper"
                                        slidesPerView={1}
                                        spaceBetween={20}
                                        navigation={true}
                                        pagination={true}
                                        modules={[Navigation, Pagination]}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                                spaceBetween: 6,
                                            },
                                            575: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className="bg-wrap">
                                                <img src={slideOneBg} alt="Bg"/>
                                            </div>
                                            <div className="content-wrap">
                                                <h2>Instant<br/> Experiences</h2>
                                                <p>Skip the date night and forget the formalities: pick a partner, pick
                                                    a location, and explore your deepest fantasies with AI-powered adult
                                                    entertainers.</p>
                                                <div className="buttons-wrap">
                                                    <a href="https://mega.nz/file/6yJB2AbJ#Yb7wo0NXpPQIWtt07JobrVF1gATKWKy-od6V9em4fJI"
                                                       target="_blank" rel="noreferrer" className="btn-main">
                                                        <span className="bg"></span>
                                                        <span className="text">Try Now</span>
                                                    </a>
                                                    <button className="btn-main btn-gray"
                                                            onClick={() => handleOpenFormModal()}>
                                                        <span className="bg"></span>
                                                        <span className="text">Pre-order</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="bg-wrap">
                                                <img src={slideTwoBg} alt="Bg"/>
                                            </div>
                                            <div className="content-wrap">
                                                <h2>Digital<br/> Strip Club</h2>
                                                <p>Hit the club without leaving the comfort of your home. Explore,
                                                    socially interact, and fulfill your desires with both real-life and
                                                    AI-powered adult entertainers.</p>
                                                <div className="buttons-wrap">
                                                    <a href="https://mega.nz/file/6yJB2AbJ#Yb7wo0NXpPQIWtt07JobrVF1gATKWKy-od6V9em4fJI"
                                                       target="_blank" rel="noreferrer" className="btn-main">
                                                        <span className="bg"></span>
                                                        <span className="text">Try Now</span>
                                                    </a>
                                                    <button className="btn-main btn-gray"
                                                            onClick={() => handleOpenFormModal()}>
                                                        <span className="bg"></span>
                                                        <span className="text">Pre-order</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="bg-wrap">
                                                <img src={slideThreeBg} alt="Bg"/>
                                            </div>
                                            <div className="content-wrap">
                                                <h2>Adult Store</h2>
                                                <p>Buy and sell lingerie, cosplay gear, sex toys, and anything and
                                                    everything
                                                    else you could imagine will take your sexual exploration to the next
                                                    level.</p>
                                                <div className="buttons-wrap">
                                                    <a href="/" target="_blank" className="btn-main btn-disabled">
                                                        <span className="bg"></span>
                                                        <span className="text">Coming Soon</span>
                                                    </a>
                                                    <button className="btn-main btn-gray"
                                                            onClick={() => handleOpenFormModal()}>
                                                        <span className="bg"></span>
                                                        <span className="text">Pre-order</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="bg-wrap">
                                                <img src={slideFourBg} alt="Bg"/>
                                            </div>
                                            <div className="content-wrap">
                                                <h2>MetaFans</h2>
                                                <p>Engage and interact with our adult entertainers outside of the club
                                                    and on our social media platform, where you'll be able to subscribe
                                                    and purchase their content.</p>
                                                <div className="buttons-wrap">
                                                    <a href="/" target="_blank" className="btn-main btn-disabled">
                                                        <span className="bg"></span>
                                                        <span className="text">Coming Soon</span>
                                                    </a>
                                                    <button className="btn-main btn-gray"
                                                            onClick={() => handleOpenFormModal()}>
                                                        <span className="bg"></span>
                                                        <span className="text">Pre-order</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="home-features" className="home-features-section">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="headline-wrap">
                                    <h3>AP Features</h3>
                                </div>
                                <div className="items-wrap">
                                    <div className="item">
                                        <div className="bg">
                                            <img src={featuresItemOne} alt="Bg"/>
                                        </div>
                                        <h5>Interact<br/> with Models</h5>
                                        <p>Find the partner that suits your taste and engage in the sexual experiences
                                            you crave.</p>
                                    </div>
                                    <div className="item">
                                        <div className="bg">
                                            <img src={featuresItemTwo} alt="Bg"/>
                                        </div>
                                        <h5>Build<br/> Relationships</h5>
                                        <p>Speak through text or voice with your partner and get to know our adult
                                            entertainers, while they get to know you.</p>
                                    </div>
                                    <div className="item">
                                        <div className="bg">
                                            <img src={featuresItemThree} alt="Bg"/>
                                        </div>
                                        <h5>Explore<br/> the Club</h5>
                                        <p>Meet up with friends, find a partner for a sexual experience, and enjoy live
                                            events.</p>
                                    </div>
                                    <div className="item">
                                        <div className="bg">
                                            <img src={featuresItemFour} alt="Bg"/>
                                        </div>
                                        <h5>Lifelike<br/> Quality</h5>
                                        <p>Lifelike model options for the most realistic digital sexual experience in
                                            the world.</p>
                                    </div>
                                    <div className="item">
                                        <div className="bg">
                                            <img src={featuresItemFive} alt="Bg"/>
                                        </div>
                                        <h5>Immerse<br/> Yourself in VR</h5>
                                        <p>Feel like you're truly there, with virtual reality.</p>
                                    </div>
                                    <div className="item">
                                        <div className="bg">
                                            <img src={featuresItemSix} alt="Bg"/>
                                        </div>
                                        <h5>Customize<br/> Your Character</h5>
                                        <p>Create a model with your own appearance, or an anonymous online personna.</p>
                                    </div>
                                    <div className="item">
                                        <div className="bg">
                                            <img src={featuresItemSeven} alt="Bg"/>
                                        </div>
                                        <h5>Use Your<br/> Favourite Toys</h5>
                                        <p>Visit the Adult Store to stock up on toys, equipment, and outfits to
                                            personalize your sexual experiences.</p>
                                    </div>
                                    <div className="item">
                                        <div className="bg">
                                            <img src={featuresItemEight} alt="Bg"/>
                                        </div>
                                        <h5>Engage Models<br/> on Social Media</h5>
                                        <p>Support our adult entertainers on their social media accounts, and enjoy
                                            their adult content shared on MetaFans.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="home-join" className="home-join-section">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="content-wrap">
                                    <div className="bg">
                                        <img src={joinBg} alt="Bg"/>
                                    </div>
                                    <div className="text-wrap">
                                        <h3>Join the Adult Playground Community</h3>
                                        <p>Adult Playground offers a judgment-free environment for users to explore
                                            their pleasures and fantasies.</p>
                                        <div className="buttons-wrap">
                                            <a href="https://discord.gg/APClub" target="_blank" rel="noreferrer">
                                                <span className="text">Join Discord</span>
                                                <span className="icon">
                                                    <img src={btnDiscordIcon} alt="Icon"/>
                                                </span>
                                            </a>
                                            <a href="https://t.me/APClubMain" target="_blank" rel="noreferrer">
                                                <span className="text">Join Telegram</span>
                                                <span className="icon">
                                                    <img src={btnTelegramIcon} alt="Icon"/>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="img-wrap">
                                        <img src={joinImg} alt="Join"/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="home-feed" className="home-feed-section">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="headline-wrap">
                                    <h3>Social Feed</h3>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="swiper-wrap">
                                    <Swiper
                                        className="home-feed-swiper"
                                        slidesPerView={3}
                                        spaceBetween={20}
                                        loop={true}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                                spaceBetween: 8,
                                                loop: true,
                                                centeredSlides: true,
                                            },
                                            575: {
                                                slidesPerView: 2,
                                                spaceBetween: 10,
                                                centeredSlides: true,
                                                loop: true
                                            },
                                            767: {
                                                slidesPerView: 3,
                                                spaceBetween: 20,
                                                centeredSlides: false,
                                                loop: false
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <a href="https://www.instagram.com/p/C04Cx6frjyc/" target="_blank"
                                               rel="noreferrer">
                                                <img src={feedItemOne} alt="Feed Item"/>
                                            </a>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <a href="https://www.tiktok.com/@apclubmain/video/7312818487246720262"
                                               target="_blank" rel="noreferrer">
                                                <img src={feedItemTwo} alt="Feed Item"/>
                                            </a>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <a href="https://twitter.com/APClubMain/status/1733191552443564522"
                                               target="_blank" rel="noreferrer">
                                                <img src={feedItemThree} alt="Feed Item"/>
                                            </a>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="home-news" className="home-news-section">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="title-wrap">
                                    <h3>News and Updates</h3>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="swiper-wrap">
                                    <Swiper
                                        className="home-news-swiper"
                                        slidesPerView={3}
                                        spaceBetween={20}
                                        loop={true}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                                spaceBetween: 8,
                                                centeredSlides: true,
                                                loop: true
                                            },
                                            575: {
                                                slidesPerView: 2,
                                                spaceBetween: 10,
                                                centeredSlides: true,
                                                loop: true
                                            },
                                            767: {
                                                centeredSlides: false,
                                                loop: false
                                            },
                                            991: {
                                                slidesPerView: 3,
                                                spaceBetween: 20,
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className="img-wrap">
                                                <img src={newsItemOne} alt="Cover"/>
                                            </div>
                                            <div className="content-wrap">
                                                <div className="headline-wrap">
                                                    <h6>News</h6>
                                                    <p>12/15/2023</p>
                                                </div>
                                                <div className="body-wrap">
                                                    <h5>Free Access Until Jan. 1st</h5>
                                                    <p>Try out the Digital Strip Club and Instant Experiences for free
                                                        now, only until January 1st. Subscription pre-orders will be
                                                        available next week.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="img-wrap">
                                                <img src={newsItemTwo} alt="Cover"/>
                                            </div>
                                            <div className="content-wrap">
                                                <div className="headline-wrap">
                                                    <h6>News</h6>
                                                    <p>12/15/2023</p>
                                                </div>
                                                <div className="body-wrap">
                                                    <h5>Alpha v1.00 Now Available!</h5>
                                                    <p>Pursue your deepest fantasies now with the first alpha release,
                                                        featuring AI model: Sapphire.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="img-wrap">
                                                <img src={newsItemThree} alt="Cover"/>
                                            </div>
                                            <div className="content-wrap">
                                                <div className="headline-wrap">
                                                    <h6>News</h6>
                                                    <p>12/15/2023</p>
                                                </div>
                                                <div className="body-wrap">
                                                    <h5>Whitepaper Release on Dec. 18th</h5>
                                                    <p>On December 18th a new full and comprehensive guide to the
                                                        product lines, features, and release schedules will be made
                                                        available.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer/>
        </>
    )
}

const CustomForm = ({status, onValidated}) => {
    let email;

    const submitSubscription = () => {
        return email && email.value.indexOf("@") > -1 && onValidated({EMAIL: email.value});
    };

    const isEmailSent = useMemo(() => status === 'success', [status]);
    const isError = useMemo(() => status === 'error', [status]);

    return (
        <div className="form-group">
            <input ref={node => (email = node)}
                   type="email" placeholder="E-mail address"
                   className="form-control"
                   required/>
            <button
                className={`btn-main ${(isEmailSent || isError) ? 'btn-disabled' : ''}`}
                disabled={isEmailSent || isError}
                aria-disabled={isEmailSent || isError}
                onClick={submitSubscription}
            >
                <span className="bg"></span>
                <span className="text">
                    {isEmailSent
                        ? 'Sent'
                        : isError
                            ? 'Error'
                            : 'Sign Up'
                    }
                </span>
            </button>
        </div>
    );
};

const MailchimpForm = () => {
    const url = "https://club.us14.list-manage.com/subscribe/post?u=62839410f7f53b732f1a1a84b&id=8ded6f76d0";

    return (
        <MailchimpSubscribe
            url={url}
            render={({subscribe, status, message}) => (
                <CustomForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                />
            )}
        />
    );
}

export default Main;
